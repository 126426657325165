@font-face {
  font-family: "Aribau-Grotesk";
  src: url("../fonts/Aribau-Grotesk/Aribau\ Grotesk\ Thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: "Aribau-Grotesk";
  src: url("../fonts/Aribau-Grotesk/Aribau\ Grotesk\ Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Aribau-Grotesk";
  src: url("../fonts/Aribau-Grotesk/Aribau\ Grotesk\ Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Aribau-Grotesk";
  src: url("../fonts/Aribau-Grotesk/Aribau\ Grotesk\ Bold.otf");
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge and Old Firefox */
* {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

html {
  font-size: 20px;
  color: #1f2933;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

body {
  font-family: "Aribau-Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container.toast__container,
.Toastify__toast.toast__toast,
.Toastify__toast-body.toast__body {
  font-family: "Aribau-Grotesk";
  padding: 0px;
  margin: 0;
  border-radius: 4px;
  width: unset;
}

.Toastify__toast-container.toast__container,
.Toastify__toast-body.toast__body {
  width: unset;
  max-width: 400px;
  right: 0;
}
.Toastify__toast.toast__toast {
  margin-bottom: 1rem;
}

.Toastify__toast.toast__toast {
  min-height: auto;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container.toast__container {
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    padding: 20px;
  }
}
