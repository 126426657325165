.date-picker {
  position: relative;
  border: 1px solid #cbd2d9;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  background-color: white;
  height: 54px;
  box-sizing: border-box;

  &.full-width {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 7px 9px -3px rgba(70, 70, 70, 0.06);
    transition: box-shadow 0.3s;
  }

  & > input,
  & > .dropdown,
  & > button {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }

  & > input {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    font-family: "Aribau-Grotesk";
    padding: 0px 16px;
    font-size: 0.9rem;
    // padding-left: 10px;
    user-select: none;
  }

  & > button {
    border: none;
    background-color: transparent;
    padding-right: 10px;
    outline: none;
    cursor: pointer;

    & > svg {
      height: 8px;
      width: auto;
      transition: transform 300ms ease-in-out;
      &.rotate {
        transform: rotateX(180deg);
        // transition: transform 0.2s;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & > .dropdown {
    padding-top: 5px;
    position: absolute;
    left: 0;
    top: 100%;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 10;

    width: 100%;

    // calender styles
    & .date-picker-calendar {
      width: 100%;
      font-family: "Aribau-Grotesk";
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      border: none;
      padding-bottom: 10px;

      & button {
        font-family: "Aribau-Grotesk";
        &:hover {
          background: #e3f9e5;
        }
      }

      & .react-calendar__navigation {
        background-color: #ffffff;
      }
      & .react-calendar__viewContainer {
        padding: 0px 5px;
      }
      & .react-calendar__navigation {
        margin-bottom: 0.25em;
      }
      & .react-calendar__navigation button {
        font-size: 0.8rem;
        font-weight: 500;
        color: #1f2933;

        display: flex;
        justify-content: center;
        align-items: center;

        &.react-calendar__navigation__next-button,
        &.react-calendar__navigation__prev-button {
          & > svg {
            height: 12px;
            width: 12px;
          }
        }
        &.react-calendar__navigation__next2-button,
        &.react-calendar__navigation__prev2-button {
          & > svg {
            height: 9px;
            width: 10px;
          }
        }
      }

      & .react-calendar__month-view__weekdays__weekday > abbr {
        text-decoration: none;
        color: #1f2933;
        font-weight: 500;
        font-size: 0.7rem;
      }

      & .react-calendar__month-view__days button {
        font-size: 0.7rem;
        font-weight: normal;
        color: #33343e;

        &[style] {
          flex-basis: 12.5% !important;
          max-width: 12.5% !important;
          overflow: hidden;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          color: #7b8794;
        }
        &.react-calendar__tile--active {
          background: #18981d;
          color: white;
          font-size: 0.8rem;
          border-radius: 50%;
          // padding: 15px 10px;
        }
      }
      & .react-calendar__year-view__months button,
      & .react-calendar__decade-view__years button,
      & .react-calendar__century-view__decades button {
        padding: 20px 15px;
        border-radius: 4px;
        color: #3e4c59;
        font-size: 0.8rem;
        &:hover {
          background: #e3f9e5;
        }
        &.react-calendar__tile--hasActive {
          color: white;
          font-size: 0.8rem;
          background: #18981d;
        }
      }
    }
  }
}
